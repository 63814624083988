























































@import '@/styles/mixin.scss';

// $bgColor: rgba(51, 51, 51, 0.1);
$fontColor1: rgb(56, 186, 238);
$fontColor2: rgb(255, 240, 1);
$poemColor: #cccccc;

.content {
  padding-top: 100px;
  // background: $bgColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  > .top {
    display: flex;
    > .logo {
      width: 160px;
      height: 160px;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > .headline {
      width: 550px;
      > h1 {
        font-size: 60px;
        line-height: 60px;
        margin: 10px 0;
        color: $fontColor1;
      }
      > h2 {
        margin: 0;
        line-height: 50px;
        font-size: 50px;
        margin: 20px 0;
        color: $fontColor2;
      }
    }
  }
  > .poem {
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    color: $poemColor;
  }
  > .center {
    margin-top: 20px;
    height: 350px;
    display: flex;
    align-items: center;

    > .splice {
      margin: 0 120px;
      width: 3px;
      height: 100%;
      background-color: #fff;
    }

    > .classify_logo {
      cursor: pointer;
      > img {
        width: 100%;
        height: auto;
      }
      > p {
        font-size: 30px;
        margin: 0;
      }
      &:nth-of-type(1) {
        > p {
          color: $fontColor1;
        }
      }
      &:nth-of-type(2) {
        > p {
          color: $fontColor2;
        }
      }
    }
  }
}
